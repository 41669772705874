angular.module('enervexSalesappApp').controller('EnergySavingsModalCtrl', function($scope, $uibModalInstance, context, paint, _) {
	$scope.showClose = true;

	$scope.cancel = function() {
		var modalInstance = $uibModalInstance.close(null);
	}

	function formatName(camelCasedName) {
        var spacedName = camelCasedName.replace(/([A-Z])/g, ' $1');
        return spacedName.charAt(0).toUpperCase() + spacedName.slice(1).trim();
    }

    function checkWidth(name) {
	    return name.length > 30;
	}


	function formatValues(energyProperties) {
		// sort so that the long ones are at the bottom of the list
		energyProperties.sort(function(a, b) {
		    if (a.fullWidth && !b.fullWidth) {
		        return 1; // Move fullWidth values to the end
		    } else if (!a.fullWidth && b.fullWidth) {
		        return -1; // Keep non-fullWidth values at the beginning
		    }
		    return 0; // Maintain the order for equal fullWidth values
		});

		// remove _id from the list
		energyProperties = _.filter(energyProperties, function(prop) {
			return prop.name != "_id"
		})

		energyProperties = _.map(energyProperties, function(prop) {
			if (prop.name == "lossTotalPerYearTherms") {
				prop.value = Math.round(prop.value)
				return prop
			} else if (prop.name == 'co2cost') {
				if (prop.value == null || prop.value == undefined) {
					prop.value = 'N/A'
				}
				return prop
			}
			else {
				return prop
			}
		})		

		return energyProperties
	}
	
	function getEnergyProps() {
		var energyProperties = _.map(_.keys($scope.design.energySavings), function (name) {
		    return {
		        name: name,
		        formattedName: formatName(name),
		        fullWidth: checkWidth(name),
		        value: $scope.design.energySavings[name]
		    };
		});

		return formatValues(energyProperties)
	}

	$scope.energyProperties = getEnergyProps()

});
